import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { publicRoutes } from '../lib/constants';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (publicRoutes.includes(router.pathname)) return;
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() =>
        router.push({
          pathname: '/login',
          query: {
            ...(window.location.pathname !== '/' && {
              redirect: window.location.pathname,
            }),
          },
        })
      );
  }, []);

  async function login(email, password, redirectUri = '/admin') {
    setError(null);
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      setUser(user);
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        router.push(
          `/account/confirm?email=${email}&code=${encodeURIComponent(password)}`
        );
        return;
      }
      router.push(redirectUri);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  async function logout() {
    await Auth.signOut();
    setUser(null);
    router.push('/login');
  }

  const values = useMemo(
    () => ({ user, login, logout, error, loading }),
    [user, error, loading]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
