import { createContext, useContext, useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';

const ConfirmationContext = createContext();

export const useConfirmation = () => useContext(ConfirmationContext);

export default function ConfirmationProvider({ children }) {
  const [dialog, setDialog] = useState({ open: false });
  function confirm(options) {
    setDialog({ ...dialog, ...options, open: true });
  }
  function closeDialog() {
    setDialog({ ...dialog, open: false });
  }
  function handleSubmit() {
    dialog.onSubmit?.();
    closeDialog();
  }
  return (
    <ConfirmationContext.Provider value={{ confirm, closeDialog }}>
      {children}
      <ConfirmationDialog
        {...dialog}
        onClose={closeDialog}
        onSubmit={handleSubmit}
      />
    </ConfirmationContext.Provider>
  );
}
