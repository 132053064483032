import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import 'react-toastify/dist/ReactToastify.min.css';
import AuthProvider from '../components/AuthProvider';
import '../styles/globals.css';
import '../lib/amplify';
import Layout from '../components/Layout';
import ConfirmationProvider from '../components/ConfirmationProvider';

const queryClient = new QueryClient();

export default function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Catholic Teachers Gazette</title>
        <meta
          name="description"
          content="Since its inception in 1994, Catholic Teacher’s Gazette has been a source of support and continuity to Catholic schools across England and Wales."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ConfirmationProvider>
            <Layout>
              <GoogleAnalytics trackPageViews gaMeasurementId="G-VSG6EWWMJL" />
              <Component {...pageProps} />
            </Layout>
          </ConfirmationProvider>
        </AuthProvider>
      </QueryClientProvider>
      <ToastContainer
        limit={3}
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
