export const publicRoutes = [
  '/login',
  '/logout',
  '/account/reset',
  '/account/forgot',
  '/account/confirm',
  '/',
  '/jobs/[id]',
  '/view/[id]',
  '/404',
];

export const passwordPolicyInfo =
  'Passwords must be at least 8 characters and should include at least 1 number and 1 uppercase letter.';

export const region = 'eu-west-2';
export const userPoolId = 'eu-west-2_thGegkhNM';
export const userPoolWebClientId = 'k0kl6ugu0q7ioo3deb784d83d';
