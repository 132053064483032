import Amplify from 'aws-amplify';
import { region, userPoolId, userPoolWebClientId } from './constants';

Amplify.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    mandatorySignIn: true,
  },
  ssr: true,
});
